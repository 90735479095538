.field-row-container {
    display: flex;
    justify-content: space-between;
    gap: 8px;    
    width: 100%;
}

.field-row-container .full-width-grid-item {
    width: 100%;
}

.field-row-container .MuiGrid-item:not(.full-width-grid-item) {
    width: 50%;
}

.field-row-container .MuiTextField-root {
    width: 100%;
}

.field-row-container .error-field-message {
    color: #c0492c;
    margin: 0px;
    font-size: 12px;
    margin-top: -6px;
}

.field-row-container .MuiTextField-root label[data-shrink="false"] {
    width: 90%;
}


.payout-card {
    margin: 32px 0px;
}

.payout-card .main {
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.payout-card .content {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.payout-card .title-label {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.payout-card .desc-label {
    color: #909196;
    font-size: 14;
    text-align: left;
}

.add-payout-fee-button,
.add-payout-fee-button:hover,
.add-payout-fee-button:focus {
    background-color: #0a315e;
    border-color: #0a315e;
}

.edit-payout-fee,
.edit-payout-fee:hover,
.edit-payout-fee:focus,
.ant-pagination-item-active a {
    color: #0a315e;
}

.cancel-payout-fee-button:hover {
    border-color: #0a315e;
    color: #0a315e;
}

.payout-fee-card .ant-pagination-item-active {
    border-color: #0a315e;
}

.payout-fee-card .payout-table-switch.ant-switch-checked {
    background-color: #0a315e;
}

@media (max-width: 600px) {
    .field-row-container {
        flex-direction: column;
    }

    .field-row-container .MuiGrid-item:not(.full-width-grid-item) {
        width: 100% !important;
    }

    .payout-fee-card .ant-card-body div div:nth-child(2) {
        flex-direction: column;
    }

    .payout-fee-card .ant-card-body div div:nth-child(2) input {
        width: 100% !important;
    }
}